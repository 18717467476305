import { createI18n } from 'vue-i18n'
import en from '../locales/en.json'
import fr from '../locales/fr.json'
import de from '../locales/de.json'
import it from '../locales/it.json'
import { useUserStore } from '~~/store/user'
import { Locales } from '~~/types/types'

export default defineNuxtPlugin(async ({ vueApp }) => {
  const appConfig = useAppConfig()
  const availableLocales = appConfig.languages

  let targetLocale
  const usersLanguage = window.navigator.language

  /**
   * Normalizes a locale string to one of the available locales
   * @param str
   * @returns Locales
   */
  function getTargetLocaleFromString(str: string) {
    const re = /^(\w\w)-?/
    const match = str.match(re)
    if (availableLocales.includes(str)){
      targetLocale = str
    } else if (match && availableLocales.includes(match[1].toLowerCase())) {
      targetLocale = usersLanguage.substring(0, 2).toLowerCase()
    } else {
      targetLocale = 'en'
    }
    return targetLocale as Locales
  }

  targetLocale = getTargetLocaleFromString(usersLanguage)

  console.debug(`Set locale to ${targetLocale}`)

  const i18nDef = createI18n({
    legacy: false,
    globalInjection: true,
    locale: targetLocale,
    messages: {
      de,
      fr,
      it,
      en,
    },
  })
  vueApp.use(i18nDef)

  const userStore = useUserStore()
  userStore.subscribeToLocaleChange((targetLocale: string | null) => {
    console.debug(`Set locale from usersLanguage to ${targetLocale}`)
    i18nDef.global.locale.value = getTargetLocaleFromString(targetLocale ?? '')
  })
})
