<script setup lang="ts">
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { getAuth } from '@firebase/auth'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '~~/store/user'
const config = useRuntimeConfig()
const userStore = useUserStore()
const { user, userState } = storeToRefs(userStore)
const i18n = useI18n()

const storeUser = ref('')
const firebaseWeb = ref('')
const firebaseNative = ref('')
const locale = ref(i18n.locale.value)

async function loadState() {
  const auth = getAuth()
  firebaseWeb.value = auth.currentUser?.uid.substring(0, 4) as string
  firebaseNative.value = (await FirebaseAuthentication.getCurrentUser()).user?.uid.substring(0, 4) as string
  storeUser.value = userStore.$state.user.uid.substring(0, 4) as string
  locale.value = i18n.locale.value
}

let timer: number

onMounted(async () => {
  timer = window.setInterval(async () => {
    const auth = getAuth()
    firebaseWeb.value = auth.currentUser?.uid.substring(0, 4) as string
    firebaseNative.value = (await FirebaseAuthentication.getCurrentUser()).user?.uid.substring(0, 4) as string
    storeUser.value = userStore.$state.user.uid.substring(0, 4) as string
    locale.value = i18n.locale.value
  }, 5000)
})

onUnmounted(() => {
  window.clearInterval(timer)
})
</script>
<template>
  <div
    class="w-2/3 m-auto bottom-0 text-center text-3xs text-stone-400 pointer-events-auto bg-white"
    @click="loadState"
  >
    Emulator: {{ config.public.useEmulators }} – {{ config.public.projectId }} – {{ config.public.buildTime }} – EM={{ user.email }} DN={{
      user.displayName
    }}
    FU={{ user.uid ? user.uid.substring(0, 4) : '-' }} US={{ userState }} SU={{ storeUser }} FW={{ firebaseWeb }} FN={{
      firebaseNative
    }}
    LO={{ locale }}
  </div>
</template>
