import { default as _91id_93xghVM2SJKPMeta } from "/home/runner/work/pictureclub/pictureclub/pages/camera-roll/[id].vue?macro=true";
import { default as camera_45rollsRGS96VvdXsMeta } from "/home/runner/work/pictureclub/pictureclub/pages/camera-rolls.vue?macro=true";
import { default as confirmation_45successJX36nJhbAYMeta } from "/home/runner/work/pictureclub/pictureclub/pages/confirmation-success.vue?macro=true";
import { default as aboutDJLhUHw0z7Meta } from "/home/runner/work/pictureclub/pictureclub/pages/dev/about.vue?macro=true";
import { default as demowfmP2U5MbWMeta } from "/home/runner/work/pictureclub/pictureclub/pages/dev/demo.vue?macro=true";
import { default as emailtemplate2mWQLGLtdcMeta } from "/home/runner/work/pictureclub/pictureclub/pages/dev/emailtemplate.vue?macro=true";
import { default as mediaiG0qiQ2z0NMeta } from "/home/runner/work/pictureclub/pictureclub/pages/dev/media.vue?macro=true";
import { default as passwordlesssFw2MES3BXMeta } from "/home/runner/work/pictureclub/pictureclub/pages/dev/passwordless.vue?macro=true";
import { default as qrscanPP2XCKbJe5Meta } from "/home/runner/work/pictureclub/pictureclub/pages/dev/qrscan.vue?macro=true";
import { default as testekcUPmIasPMeta } from "/home/runner/work/pictureclub/pictureclub/pages/dev/test.vue?macro=true";
import { default as devtoolsC1ycBkOKTZMeta } from "/home/runner/work/pictureclub/pictureclub/pages/devtools.vue?macro=true";
import { default as email_45actions0jXeVn1E2UMeta } from "/home/runner/work/pictureclub/pictureclub/pages/email-actions.vue?macro=true";
import { default as guestzq5NVgHHT4Meta } from "/home/runner/work/pictureclub/pictureclub/pages/guest.vue?macro=true";
import { default as indexGsGqGsvfEUMeta } from "/home/runner/work/pictureclub/pictureclub/pages/index.vue?macro=true";
import { default as loginAC55XugWjhMeta } from "/home/runner/work/pictureclub/pictureclub/pages/login.vue?macro=true";
import { default as logouttdqMUpkob9Meta } from "/home/runner/work/pictureclub/pictureclub/pages/logout.vue?macro=true";
import { default as passwordless8RczjiMDVfMeta } from "/home/runner/work/pictureclub/pictureclub/pages/passwordless.vue?macro=true";
import { default as previewHMM7cOPx2kMeta } from "/home/runner/work/pictureclub/pictureclub/pages/preview.vue?macro=true";
import { default as printable_45qrcode2g5b1jkOK1Meta } from "/home/runner/work/pictureclub/pictureclub/pages/printable-qrcode.vue?macro=true";
import { default as privacyhdFy2Fm14LMeta } from "/home/runner/work/pictureclub/pictureclub/pages/privacy.vue?macro=true";
import { default as aboutWYIygkPB9sMeta } from "/home/runner/work/pictureclub/pictureclub/pages/profile/about.vue?macro=true";
import { default as change_45passwordOSH80wpNYtMeta } from "/home/runner/work/pictureclub/pictureclub/pages/profile/change-password.vue?macro=true";
import { default as deleteaccount73RYPrcOdXMeta } from "/home/runner/work/pictureclub/pictureclub/pages/profile/deleteaccount.vue?macro=true";
import { default as indexDnQzIZK9OAMeta } from "/home/runner/work/pictureclub/pictureclub/pages/profile/index.vue?macro=true";
import { default as language_45switchP3cBzT0qEdMeta } from "/home/runner/work/pictureclub/pictureclub/pages/profile/language-switch.vue?macro=true";
import { default as profile_45basicsFDNOgB88sMMeta } from "/home/runner/work/pictureclub/pictureclub/pages/profile/profile-basics.vue?macro=true";
import { default as qrscan_45insidewmuQqP8M4DMeta } from "/home/runner/work/pictureclub/pictureclub/pages/qrscan-inside.vue?macro=true";
import { default as registerCMtt1bltgwMeta } from "/home/runner/work/pictureclub/pictureclub/pages/register.vue?macro=true";
import { default as startdu2bB2eN4mMeta } from "/home/runner/work/pictureclub/pictureclub/pages/start.vue?macro=true";
export default [
  {
    name: _91id_93xghVM2SJKPMeta?.name ?? "camera-roll-id",
    path: _91id_93xghVM2SJKPMeta?.path ?? "/camera-roll/:id()",
    meta: _91id_93xghVM2SJKPMeta || {},
    alias: _91id_93xghVM2SJKPMeta?.alias || [],
    redirect: _91id_93xghVM2SJKPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/camera-roll/[id].vue").then(m => m.default || m)
  },
  {
    name: camera_45rollsRGS96VvdXsMeta?.name ?? "camera-rolls",
    path: camera_45rollsRGS96VvdXsMeta?.path ?? "/camera-rolls",
    meta: camera_45rollsRGS96VvdXsMeta || {},
    alias: camera_45rollsRGS96VvdXsMeta?.alias || [],
    redirect: camera_45rollsRGS96VvdXsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/camera-rolls.vue").then(m => m.default || m)
  },
  {
    name: confirmation_45successJX36nJhbAYMeta?.name ?? "confirmation-success",
    path: confirmation_45successJX36nJhbAYMeta?.path ?? "/confirmation-success",
    meta: confirmation_45successJX36nJhbAYMeta || {},
    alias: confirmation_45successJX36nJhbAYMeta?.alias || [],
    redirect: confirmation_45successJX36nJhbAYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/confirmation-success.vue").then(m => m.default || m)
  },
  {
    name: aboutDJLhUHw0z7Meta?.name ?? "dev-about",
    path: aboutDJLhUHw0z7Meta?.path ?? "/dev/about",
    meta: aboutDJLhUHw0z7Meta || {},
    alias: aboutDJLhUHw0z7Meta?.alias || [],
    redirect: aboutDJLhUHw0z7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/dev/about.vue").then(m => m.default || m)
  },
  {
    name: demowfmP2U5MbWMeta?.name ?? "dev-demo",
    path: demowfmP2U5MbWMeta?.path ?? "/dev/demo",
    meta: demowfmP2U5MbWMeta || {},
    alias: demowfmP2U5MbWMeta?.alias || [],
    redirect: demowfmP2U5MbWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/dev/demo.vue").then(m => m.default || m)
  },
  {
    name: emailtemplate2mWQLGLtdcMeta?.name ?? "dev-emailtemplate",
    path: emailtemplate2mWQLGLtdcMeta?.path ?? "/dev/emailtemplate",
    meta: emailtemplate2mWQLGLtdcMeta || {},
    alias: emailtemplate2mWQLGLtdcMeta?.alias || [],
    redirect: emailtemplate2mWQLGLtdcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/dev/emailtemplate.vue").then(m => m.default || m)
  },
  {
    name: mediaiG0qiQ2z0NMeta?.name ?? "dev-media",
    path: mediaiG0qiQ2z0NMeta?.path ?? "/dev/media",
    meta: mediaiG0qiQ2z0NMeta || {},
    alias: mediaiG0qiQ2z0NMeta?.alias || [],
    redirect: mediaiG0qiQ2z0NMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/dev/media.vue").then(m => m.default || m)
  },
  {
    name: passwordlesssFw2MES3BXMeta?.name ?? "dev-passwordless",
    path: passwordlesssFw2MES3BXMeta?.path ?? "/dev/passwordless",
    meta: passwordlesssFw2MES3BXMeta || {},
    alias: passwordlesssFw2MES3BXMeta?.alias || [],
    redirect: passwordlesssFw2MES3BXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/dev/passwordless.vue").then(m => m.default || m)
  },
  {
    name: qrscanPP2XCKbJe5Meta?.name ?? "dev-qrscan",
    path: qrscanPP2XCKbJe5Meta?.path ?? "/dev/qrscan",
    meta: qrscanPP2XCKbJe5Meta || {},
    alias: qrscanPP2XCKbJe5Meta?.alias || [],
    redirect: qrscanPP2XCKbJe5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/dev/qrscan.vue").then(m => m.default || m)
  },
  {
    name: testekcUPmIasPMeta?.name ?? "dev-test",
    path: testekcUPmIasPMeta?.path ?? "/dev/test",
    meta: testekcUPmIasPMeta || {},
    alias: testekcUPmIasPMeta?.alias || [],
    redirect: testekcUPmIasPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/dev/test.vue").then(m => m.default || m)
  },
  {
    name: devtoolsC1ycBkOKTZMeta?.name ?? "devtools",
    path: devtoolsC1ycBkOKTZMeta?.path ?? "/devtools",
    meta: devtoolsC1ycBkOKTZMeta || {},
    alias: devtoolsC1ycBkOKTZMeta?.alias || [],
    redirect: devtoolsC1ycBkOKTZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/devtools.vue").then(m => m.default || m)
  },
  {
    name: email_45actions0jXeVn1E2UMeta?.name ?? "email-actions",
    path: email_45actions0jXeVn1E2UMeta?.path ?? "/email-actions",
    meta: email_45actions0jXeVn1E2UMeta || {},
    alias: email_45actions0jXeVn1E2UMeta?.alias || [],
    redirect: email_45actions0jXeVn1E2UMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/email-actions.vue").then(m => m.default || m)
  },
  {
    name: guestzq5NVgHHT4Meta?.name ?? "guest",
    path: guestzq5NVgHHT4Meta?.path ?? "/guest",
    meta: guestzq5NVgHHT4Meta || {},
    alias: guestzq5NVgHHT4Meta?.alias || [],
    redirect: guestzq5NVgHHT4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/guest.vue").then(m => m.default || m)
  },
  {
    name: indexGsGqGsvfEUMeta?.name ?? "index",
    path: indexGsGqGsvfEUMeta?.path ?? "/",
    meta: indexGsGqGsvfEUMeta || {},
    alias: indexGsGqGsvfEUMeta?.alias || [],
    redirect: indexGsGqGsvfEUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginAC55XugWjhMeta?.name ?? "login",
    path: loginAC55XugWjhMeta?.path ?? "/login",
    meta: loginAC55XugWjhMeta || {},
    alias: loginAC55XugWjhMeta?.alias || [],
    redirect: loginAC55XugWjhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logouttdqMUpkob9Meta?.name ?? "logout",
    path: logouttdqMUpkob9Meta?.path ?? "/logout",
    meta: logouttdqMUpkob9Meta || {},
    alias: logouttdqMUpkob9Meta?.alias || [],
    redirect: logouttdqMUpkob9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: passwordless8RczjiMDVfMeta?.name ?? "passwordless",
    path: passwordless8RczjiMDVfMeta?.path ?? "/passwordless",
    meta: passwordless8RczjiMDVfMeta || {},
    alias: passwordless8RczjiMDVfMeta?.alias || [],
    redirect: passwordless8RczjiMDVfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/passwordless.vue").then(m => m.default || m)
  },
  {
    name: previewHMM7cOPx2kMeta?.name ?? "preview",
    path: previewHMM7cOPx2kMeta?.path ?? "/preview",
    meta: previewHMM7cOPx2kMeta || {},
    alias: previewHMM7cOPx2kMeta?.alias || [],
    redirect: previewHMM7cOPx2kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: printable_45qrcode2g5b1jkOK1Meta?.name ?? "printable-qrcode",
    path: printable_45qrcode2g5b1jkOK1Meta?.path ?? "/printable-qrcode",
    meta: printable_45qrcode2g5b1jkOK1Meta || {},
    alias: printable_45qrcode2g5b1jkOK1Meta?.alias || [],
    redirect: printable_45qrcode2g5b1jkOK1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/printable-qrcode.vue").then(m => m.default || m)
  },
  {
    name: privacyhdFy2Fm14LMeta?.name ?? "privacy",
    path: privacyhdFy2Fm14LMeta?.path ?? "/privacy",
    meta: privacyhdFy2Fm14LMeta || {},
    alias: privacyhdFy2Fm14LMeta?.alias || [],
    redirect: privacyhdFy2Fm14LMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: aboutWYIygkPB9sMeta?.name ?? "profile-about",
    path: aboutWYIygkPB9sMeta?.path ?? "/profile/about",
    meta: aboutWYIygkPB9sMeta || {},
    alias: aboutWYIygkPB9sMeta?.alias || [],
    redirect: aboutWYIygkPB9sMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/profile/about.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordOSH80wpNYtMeta?.name ?? "profile-change-password",
    path: change_45passwordOSH80wpNYtMeta?.path ?? "/profile/change-password",
    meta: change_45passwordOSH80wpNYtMeta || {},
    alias: change_45passwordOSH80wpNYtMeta?.alias || [],
    redirect: change_45passwordOSH80wpNYtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: deleteaccount73RYPrcOdXMeta?.name ?? "profile-deleteaccount",
    path: deleteaccount73RYPrcOdXMeta?.path ?? "/profile/deleteaccount",
    meta: deleteaccount73RYPrcOdXMeta || {},
    alias: deleteaccount73RYPrcOdXMeta?.alias || [],
    redirect: deleteaccount73RYPrcOdXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/profile/deleteaccount.vue").then(m => m.default || m)
  },
  {
    name: indexDnQzIZK9OAMeta?.name ?? "profile",
    path: indexDnQzIZK9OAMeta?.path ?? "/profile",
    meta: indexDnQzIZK9OAMeta || {},
    alias: indexDnQzIZK9OAMeta?.alias || [],
    redirect: indexDnQzIZK9OAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: language_45switchP3cBzT0qEdMeta?.name ?? "profile-language-switch",
    path: language_45switchP3cBzT0qEdMeta?.path ?? "/profile/language-switch",
    meta: language_45switchP3cBzT0qEdMeta || {},
    alias: language_45switchP3cBzT0qEdMeta?.alias || [],
    redirect: language_45switchP3cBzT0qEdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/profile/language-switch.vue").then(m => m.default || m)
  },
  {
    name: profile_45basicsFDNOgB88sMMeta?.name ?? "profile-profile-basics",
    path: profile_45basicsFDNOgB88sMMeta?.path ?? "/profile/profile-basics",
    meta: profile_45basicsFDNOgB88sMMeta || {},
    alias: profile_45basicsFDNOgB88sMMeta?.alias || [],
    redirect: profile_45basicsFDNOgB88sMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/profile/profile-basics.vue").then(m => m.default || m)
  },
  {
    name: qrscan_45insidewmuQqP8M4DMeta?.name ?? "qrscan-inside",
    path: qrscan_45insidewmuQqP8M4DMeta?.path ?? "/qrscan-inside",
    meta: qrscan_45insidewmuQqP8M4DMeta || {},
    alias: qrscan_45insidewmuQqP8M4DMeta?.alias || [],
    redirect: qrscan_45insidewmuQqP8M4DMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/qrscan-inside.vue").then(m => m.default || m)
  },
  {
    name: registerCMtt1bltgwMeta?.name ?? "register",
    path: registerCMtt1bltgwMeta?.path ?? "/register",
    meta: registerCMtt1bltgwMeta || {},
    alias: registerCMtt1bltgwMeta?.alias || [],
    redirect: registerCMtt1bltgwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/register.vue").then(m => m.default || m)
  },
  {
    name: startdu2bB2eN4mMeta?.name ?? "start",
    path: startdu2bB2eN4mMeta?.path ?? "/start",
    meta: startdu2bB2eN4mMeta || {},
    alias: startdu2bB2eN4mMeta?.alias || [],
    redirect: startdu2bB2eN4mMeta?.redirect || undefined,
    component: () => import("/home/runner/work/pictureclub/pictureclub/pages/start.vue").then(m => m.default || m)
  }
]